import * as TYPES from './action-types';

export function toggleStudentSelect(studentId) {
  return {
    type: TYPES.DASHBOARD_TOGGLE_STUDENT_SELECT,
    studentId
  };
}

export function selectAllStudents() {
  return {
    type: TYPES.DASHBOARD_SELECT_ALL_STUDENTS
  };
}

export function deselectAllStudents() {
  return {
    type: TYPES.DASHBOARD_DESELECT_ALL_STUDENTS
  };
}

export function pasteToSelectedStudents(message) {
  return {
    type: TYPES.DASHBOARD_PASTE_TO_SELECTED_STUDENTS,
    message
  };
}

export function clearAllMessages() {
  return {
    type: TYPES.DASHBOARD_CLEAR_ALL_INPUT_BOXES
  };
}

export function filterStudents(filters) {
  return {
    type: TYPES.DASHBOARD_FILTER_STUDENTS,
    filters,
  };
}

export function clearFilterStudents() {
  return {
    type: TYPES.DASHBOARD_CLEAR_FILTER_STUDENTS
  };
}

export function setIsActiveFilter(filterValue) {
  return {
    type: TYPES.DASHBOARD_SET_IS_ACTIVE_FILTER,
    isActiveFilter: filterValue
  };
}


export function loadGenderSelectionsSuccess(genderSelections) {
  return {
    type: TYPES.LOAD_GENDER_SELECTIONS_SUCCESS,
    genderSelections
  };
}

export function loadSexualOrientationSelectionsSuccess(orientationSelections) {
  return {
    type: TYPES.LOAD_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS,
    orientationSelections
  };
}

export function loadPronounSelectionsSuccess(pronounSelections) {
  return {
    type: TYPES.LOAD_PRONOUNS_SELECTIONS_SUCCESS,
    pronounSelections
  };
}

export function loadRaceSelectionsSuccess(raceSelections) {
  return {
    type: TYPES.LOAD_RACE_SELECTIONS_SUCCESS,
    raceSelections
  };
}

export function loadCoachRaceSelectionsSuccess(coachRaceSelections) {
  return {
    type: TYPES.LOAD_COACH_RACE_SELECTIONS_SUCCESS,
    coachRaceSelections
  }
}

export function loadCoachGenderSelectionsSuccess(coachGenderSelections) {
  return {
    type: TYPES.LOAD_COACH_GENDER_SELECTIONS_SUCCESS,
    coachGenderSelections
  }
}

export function loadCoachSexualOrientationSelectionsSuccess(coachSexualOrientationSelections) {
  return {
    type: TYPES.LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS,
    coachSexualOrientationSelections
  }
}

export function loadCoachLanguagesSuccess(coachLanguages) {
  return {
    type: TYPES.LOAD_COACH_LANGUAGES_SELECTIONS_SUCCESS,
    coachLanguages
  }
}

export function loadPartnerSelectionSuccess(partnerSelection) {
  return {
    type: TYPES.LOAD_PARTNERS_SELECTIONS_SUCCESS,
    partnerSelection
  };
}

export function loadManagerPermissionSuccess(permissions) {
  return {
    type: TYPES.LOAD_MANAGERS_PERMISSION_SUCCESS,
    permissions
  }
}
