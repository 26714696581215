import { NgRedux } from '@angular-redux/store';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { CoachService } from 'app/core/services/coach.service';
import { NotificationService } from 'app/core/services/notification.service';
import { IAppState } from 'app/redux/store';
import { SELECTORS as AppSelectors } from 'app/redux/reducers/app.reducer';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-coach-language',
  templateUrl: './coach-language.component.html',
  styleUrls: ['./coach-language.component.scss']
})
export class CoachLanguageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private coachSubject = new BehaviorSubject<any | null>(null);

  @Input() set coach(value: any) {
    this.coachSubject.next(value);
  }

  coachLanguages: any = [];
  selectedLanguageIds: string[] = [];
  customLanguage = '';

  constructor(
    private coachService: CoachService,
    private notificationService: NotificationService,
    private adminService: AdminSettingsService,
    private ngRedux: NgRedux<IAppState>,
  ) { 
  }

  ngOnInit(): void {
    this.adminService.loadCoachLanguageSelections();
    this.loadCoachLanguages();
    
    this.coachSubject
      .pipe(
        distinctUntilChanged((prev, curr) => 
          JSON.stringify(prev?._data?.preferredLanguages) === 
          JSON.stringify(curr?._data?.preferredLanguages)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(coach => {
        if (coach?._data?.preferredLanguages) {
          this.selectedLanguageIds = coach._data.preferredLanguages.map(lang => lang.languageId);
          this.updateLanguageSelections();
        }
      });
  }

  loadCoachLanguages(): void {
    this.ngRedux.select(AppSelectors.coachLanguageOptions)
      .pipe(
        map(data => {
          return data ? Object.entries(data).map(([key, value]) => ({
            label: key,
            value
          })) : [];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.coachLanguages = data;
        if (this.coachSubject.value?._data?.preferredLanguages) {
          this.selectedLanguageIds = this.coachSubject.value._data.preferredLanguages
            .map(lang => lang.languageId);
          this.updateLanguageSelections();
        }
      });
  }

  updateLanguageSelections(): void {
    this.coachLanguages = this.coachLanguages.map(lang => {
      const isSelected = this.selectedLanguageIds.includes(lang.label);
      if (lang.value === 'Other' && isSelected) {
        const otherLanguage = this.coachSubject.value?._data.preferredLanguages
          .find(pl => pl.languageId === '5');
        if (otherLanguage) {
          this.customLanguage = otherLanguage.customLanguage;
        }
      }
      return {
        ...lang,
        selected: isSelected
      };
    });
  }

  toggleLanguageSelection(languageId: string): void {
    const index = this.selectedLanguageIds.indexOf(languageId);
    if (index === -1) {
      this.selectedLanguageIds.push(languageId);
    } else {
      this.selectedLanguageIds.splice(index, 1);
    }
    this.updateLanguageSelections();
  }

  saveLanguages(): void {
    const coach = this.coachSubject.value;
    if (!coach?._data?._user) {
      this.notificationService.toastr.error('Coach ID not found');
      return;
    }

    const preferredLanguages = this.selectedLanguageIds.map(id => ({
      languageId: id,
      customLanguage: id === '5' ? this.customLanguage : ''
    }));

    this.coachService.updateCoachLanguageSelections(
      coach._data._id,
      preferredLanguages
    ).subscribe({
      next: (response: any) => {
        if (response.error) {
          this.notificationService.toastr.error(response.message);
        } else {
          this.notificationService.toastr.success('Languages updated successfully');
        }
      },
      error: () => {
        this.notificationService.toastr.error('An unexpected error occurred');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
