
import { Observable, of } from 'rxjs';

import {
  catchError,
  debounceTime,
  filter,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  merge as mergeOperator
} from 'rxjs/operators';

import * as moment from 'moment-timezone';
import _ from 'lodash';

import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Component, OnInit, OnDestroy, Input, ViewChild, Inject, ElementRef } from '@angular/core';

import { StudentService } from 'app/core/services/student.service';
import { CoachService } from 'app/core/services/coach.service';
import { ConversationService } from 'app/core/services/conversation.service';
import { NotificationService } from 'app/core/services/notification.service';
import { CannedMessagesService } from 'app/core/services/canned-messages.service';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { ApiService } from 'app/core/services/api.service';
import { transformShouldCollapseName } from 'app/redux/reducers/conversation.reducer';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { AppService } from 'app/core/services/app.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';
import { loadConversationsSuccess } from 'app/redux/actions/conversation.actions';
import { Subscription } from 'rxjs';
import { SocketIOService } from 'app/core/services/socket.service';
import { User } from 'app/models/user';
import { UtilityService } from 'app/core/services/util.service';
import { RecommendationsService } from 'app/core/services/recommendations.service';
import { LeadService } from 'app/core/services/lead.service';

export interface CrisisDialogData {
  messages: any[],
}
@Component({
  selector: 'crisis-detection-dialog',
  templateUrl: './dialogs/crisis-detection-dialog.html',
})
export class CrisisDetectionDialogComponent {

  selectedItems = [];
  isLoading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CrisisDialogData,
    private crisisDialog: MatDialog,
    private conversationService: ConversationService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  public async savePotentialCrisisMessages() {
    let processed = 0
    for (let item of this.data.messages) {
      this.isLoading = true

      let newTags = [...item.tags];
      if (_.find(this.selectedItems, i => item._id === i._id)) {
        newTags = [...item.tags, { name: 'crisis', category: 'topic' }]
      }

      this.conversationService
        .attachTagsToMessage(item._id, newTags)
        .subscribe(() => {
          this.isLoading = false;
          processed++

          if (processed === this.data.messages.length) {
            this.updateConversationWithCrisis(this.data.messages[0]._student, false)
            this.crisisDialog.closeAll();
          }
        });
    }
  }

  declinePotentialCrisisMessages() {
    for (let item of this.data.messages) {
      this.isLoading = true

      let newTags = [...item.tags];

      this.conversationService
        .attachTagsToMessage(item._id, newTags)
        .subscribe(() => {
          this.isLoading = false;
        });
    }
    this.updateConversationWithCrisis(this.data.messages[0]._student, false)
    this.crisisDialog.closeAll();
  }

  closeCrisisDialog() {
    this.crisisDialog.closeAll();
  }

  updateConversationWithCrisis(memberId, isCrisis) {
    try {
      this.conversationService.getAssignedStudentsConversations()
        .subscribe((conversations: any) => {
          const currentSelector = _.findIndex(conversations, (i: any) => i.lastMessage._student === memberId)

          conversations[currentSelector].potentialCrisisTexts = isCrisis
          this.ngRedux.dispatch(loadConversationsSuccess(conversations));
        });
    } catch (error) {
      console.error(error)
    }
  }
}

export interface LinkUnlinkRecommendationDialogData {
  recommendation: any
}
@Component({
  selector: 'link-unlink-recommendations-dialog',
  templateUrl: './dialogs/link-unlink-recommendation-dialog.html',
})
export class LinkUnlinkRecommendationDialogComponent {

  linkDate = '';
  isLoading = false;
  recommendation;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LinkUnlinkRecommendationDialogData,
    private linkUnlinkRecommendationDialog: MatDialog,
    private recommendationService: RecommendationsService,
    private notificationService: NotificationService
  ) {
      this.recommendation = data.recommendation;
      this.linkDate = moment(data.recommendation.linkedDate).format('YYYY-MM-DD')
  }

     linkRecommendation(){
      const { text, isCompleted, _id: id, userId } = this.recommendation;
       const modifiedRecommendation = {
        id,
        linkedDate: this.linkDate,
        linkType: 'CONVERSATION',
        userId,
        text,
        isCompleted
       }

         this.recommendationService.linkRecommendationsToConversation(modifiedRecommendation).subscribe(() => {
            this.recommendationService.getRecommendations(userId);
            this.linkUnlinkRecommendationDialog.closeAll();
            this.notificationService.toastr.success('Recommendation linked successfully');
         })
      }

      unlinkRecommendation(){
        const { text, isCompleted, _id: id, userId } = this.recommendation;
        const modifiedRecommendation = {
         id,
         linkedDate: '',
         linkType: 'CONVERSATION',
         userId,
         text,
         isCompleted
         }

         this.recommendationService.linkRecommendationsToConversation(modifiedRecommendation).subscribe(() => {
          this.recommendationService.getRecommendations(userId);
          this.linkUnlinkRecommendationDialog.closeAll();
          this.notificationService.toastr.success('Recommendation unlinked successfully');
       })
      }

      closeLinkRecommendationDialog() {
        this.linkUnlinkRecommendationDialog.closeAll();
      }
}


export interface AllPendingCrisisDialogData {
  messages: any[],
}
@Component({
  selector: 'crisis-pending-detection-dialog',
  templateUrl: './dialogs/crisis-pending-detection-dialog.html',
  styleUrls: ['./dialogs/styles.scss']
})

export class CrisisPendingDetectionDialogComponent {

  allPendingCrisis: any;
  crisis = [];
  selectedItems = [];
  loading = true;
  memberIds = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AllPendingCrisisDialogData,
    public crisisPendingDialog: MatDialog,
    private conversationService: ConversationService,
    private coachServices: CoachService,
    private ngRedux: NgRedux<IAppState>,

  ) { }

  ngOnInit() {
    this.loading = true;
    this.coachServices.getPotentialCrisisTexts().subscribe(
      result => {
        this.allPendingCrisis = result;
        this.crisis = this.allPendingCrisis.data.crisisConversations;
        this.loading = false;
      }
    )
  }

  updateConversationWithCrisis(memberId, isCrisis) {
    try {
      this.conversationService.getAssignedStudentsConversations()
        .subscribe((conversations: any) => {
          for (let i = 0; i < memberId.length; i++) {
            const currentSelector = _.findIndex(conversations, (i: any) => i.lastMessage._studentmemberId === memberId[i]);
            conversations[currentSelector].potentialCrisisTexts = isCrisis
          }
          this.ngRedux.dispatch(loadConversationsSuccess(conversations));
        });
    } catch (error) {
      console.error(error)
    }
  }

  declinePotentialCrisisMessages() {
    this.memberIds = [];
    for (let item of this.crisis) {
      this.loading = true

      let newTags = [...item.tags];
      if (this.memberIds.indexOf(item._student) == -1) this.memberIds.push(item._student);
      this.conversationService
        .attachTagsToMessage(item._id, newTags)
        .subscribe(() => {
          this.loading = false;
        });
    }
    this.updateConversationWithCrisis(this.memberIds, false);
    this.crisisPendingDialog.closeAll();
  }

  public async savePotentialCrisisMessages() {
    let processed = 0
    this.memberIds = [];
    for (let item of this.crisis) {
      this.loading = true

      let newTags = [...item.tags];
      if (_.find(this.selectedItems, i => item._id === i._id)) {
        newTags = [...item.tags, { name: 'crisis', category: 'topic' }]
      }

      if (this.memberIds.indexOf(item._student) == -1) this.memberIds.push(item._student);

      this.conversationService
        .attachTagsToMessage(item._id, newTags)
        .subscribe(() => {
          this.loading = false;
          processed++

          if (processed === this.crisis.length) {
            this.updateConversationWithCrisis(this.memberIds, false);
            this.crisisPendingDialog.closeAll();
          }
        });
    }
  }

  closeCrisisDialog() {
    this.crisisPendingDialog.closeAll();
  }
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  isLead = false;
  leadData: any = null;
  private MEMBER_UPDATE_SOCKET_EVENT_NAME = 'USER:PROFILE_UPDATE:MEMBER:';
  private MEMBER_EOS_SOCKET_EVENT_NAME = 'USER:EOS_UPDATE:MEMBER:';
  private memberChangesSubscription: Subscription;
  private memberEOSChangeSubscription: Subscription;
  @ViewChild('searchInput') searchInput: ElementRef;
    private isDropdownOpen = false;
  private dropdownPositioner: any;

  studentSlots$: Observable<any>;

  officialTimezone = 'America/New_York';
  shouldRefetchRecommendation=false;

  studentId;
  messages = [];
  student: any = { _data: {} };

  cannedMessages;
  selectedMessages;
  cannedCategories;
  selectedCategory;
  allCannedMessages = [];
  selectedCannedMessage = '';
  memberEngaged = false;
  memberEOS = false;

  isMessagePosting = false;

  isSearchMode = false;
  isSearching = false;
  searchFailed = false;
  searchModel;
  hideSearchingWhenUnsubscribed = new Observable(() => () => this.isSearching = false);

  isTimeSlot = false;

  studentFeelings;
  studentSlots = [];
  formControls = {
    selecteDate: this.utilService.isoToDatePickerFormat(new Date()) || 'dd/mm/yyy'
  };

  toggleCalender = 'textSearch'

  @Input() message = '';

  showLoadMore = true;
  isLoadingMore = false;

  @ViewChild('virtualScroller', { read: null, static: false }) virtualScroller: VirtualScrollerComponent;

  searchedMessages = null;

  textRestrictions = {
    isHiddenFromCoach: false,
    isHiddenFromSupervisor: false
  };

  selectedConversationIds = [];
  selectedConversationsDict: any = {};

  isReviewMode = null;
  isAdmin = false;
  setMemberEngagedLoading = false;
  setMemberEOSLoading = false;
  potentialCrisisTexts = [];
  crisisDialogRef: MatDialogRef<CrisisDetectionDialogComponent> = null;
  recommendationsLinkUnlinkDialogRef: MatDialogRef<LinkUnlinkRecommendationDialogComponent> = null;
  allpendingCrisisDialogRef: MatDialogRef<CrisisPendingDetectionDialogComponent> = null;
  canText = true;
  currentUser: User;
  rolesToCheckShift = ['Coach', 'Manager'];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminSettingsService,
    private studentService: StudentService,
    private notificationService: NotificationService,
    private conversationService: ConversationService,
    private cannedMessagesService: CannedMessagesService,
    private api: ApiService,
    private appService: AppService,
    public crisisDetectionDialog: MatDialog,
    public allpendingCrisisDialog: MatDialog,
    private ngRedux: NgRedux<IAppState>,
    private rxsocket: SocketIOService,
    private utilService: UtilityService,
    private leadService: LeadService,
  ) { }

  async ngOnInit() {
    const crisisHandler = ['Admin', 'Lss'];
    const session = this.appService.getUrlQueryStringParam('session');
    this.currentUser = this.appService.currentUser;

    if (session) {
      this.isReviewMode = session === 'review';
    }
    this.isAdmin = crisisHandler.includes(this.currentUser._role.title) ? true : false;
    await this.adminService.loadProfileSections();

    // Run handleParamChange everytime the route params change
    this.route.params.pipe().subscribe(params => this.handleParamsChange(params));
    this.route.queryParams.pipe().subscribe(params => this.handleQueryParamsChange(params));

    this.scrollToBottom();
    this.conversationService.getCurrentConversation()
      .subscribe(this.onMessagesLoaded);

      this.conversationService.getCurrentLeadConversation()
      .subscribe(this.onMessagesLoaded);

    this.cannedMessagesService.getCategories()
      .subscribe(categories => {
        this.cannedCategories = categories;
      });
    this.cannedMessagesService.getCannedMessages()
      .subscribe(messages => {
        this.cannedMessages = messages;
      });

    if (this.rolesToCheckShift.includes(this.currentUser._role.title)) {
      this.canText = this.currentUser.isShiftPeriod;
    }

  }

  get displayName(): string {
    if (this.isLead && this.leadData?.firstName && this.leadData?.lastName) {
      return `${this.leadData.firstName} ${this.leadData.lastName?.charAt(0)}`.trim();
    }
    if (!this.isLead && this.student?.firstName && this.student?.lastName) {
      return `${this.student.firstName} ${this.student.lastName?.charAt(0)}`.trim();
    }
    return '';
  }

  ngOnDestroy() {
    if (this.dropdownPositioner) {
      clearInterval(this.dropdownPositioner);
    }
    this.conversationService.unsetCurrentConversation();
    this.conversationService.unsetCurrentLeadConversation();
  }


  onViewAllCrisis() {
    this.allpendingCrisisDialogRef = this.allpendingCrisisDialog.open(CrisisPendingDetectionDialogComponent, {
      disableClose: true,
      autoFocus: true,
      data: {
        messages: this.potentialCrisisTexts
      }
    });
  }

  onMessageChange(message = '') {
    this.message = message;
  }

  setFilterCategory(category) {
    this.selectedMessages = this.cannedMessages[category] || [];
  }

  studentEscalatedChange(value) {
    this.student._data.isEscalated = value;
    this.studentService.updateStudentEscalatedMode(
      this.student._id,
      this.student._data.isEscalated
    ).subscribe(updatedStudent => {
      const message = `Escalated mode for ${this.student.firstName} turned
        ${value ? '<b>ON</b>' : '<b>OFF</b>'}.`;
      this.notificationService.toastr.warning(message, {
        closeButton: true
      });
    }, err => {
      this.student._data.isEscalated = !this.student._data.isEscalated;
    });
  }

  refetchCurrentStudent() {
    this.studentService.getMember(this.studentId, true).then(member => {
      this.onStudentLoaded(member)
    }).catch(err => {
      console.error(err)
    })
  }


  private handleParamsChange(params) {
    const id = params['student-id'];
    this.studentId = id;

    this.messages = [];
    this.showLoadMore = true;

    this.leadService.getLead(id).subscribe(
      (data) => {
        this.isLead = !!(data.lead ?? false);
        if (this.isLead) {
          this.leadData = data.lead;
          this.handleLeadChat(this.leadData._id);
          this.student = { _data: {} };
        } else {
          this.studentService.getMember(id, true).then(member => {
            this.onStudentLoaded(member);
          }).catch(err => {
            this.notificationService.toastr.error(err.message, 'Failed to load student profile');
          });
          this.conversationService.loadConversation(id);
          this.conversationService.setCurrentConversation(id);
          this.loadSlotsForToday();
        }
      }
    )
  }

  private handleLeadChat(leadId: string) {
    if (leadId) {
      this.configureChatForLead();
      this.conversationService.loadLeadConversation(leadId)
      this.conversationService.setCurrentLeadConversation(leadId);
    }
  }

  private configureChatForLead() {
    // Disable member-specific features
    this.isTimeSlot = false;
    this.memberEngaged = false;
    this.memberEOS = false;
  }

  private handleQueryParamsChange(params) {

    // Handle loading searched messages here
    if (params['jump_to']) {
      let d = moment(params['jump_to']);
      // d = d.toISOString();

      let dateStr = (d.toISOString() as unknown) as string

      this.isSearchMode = true;

      this.conversationService.getConversation(this.studentId, { after_date: dateStr, limit: 4 })
        .subscribe((res: any) => {
          this.searchedMessages = res.data;
        });
    } else {
      this.isSearchMode = false;
      this.searchedMessages = null;
    }
  }

  onClickLoadMore(data) {
    this.loadMore(data);
  }

  private onStudentLoaded = student => {
    // TODO: Check and move this action to Redux
    // if (!student) { return; }
    this.student = student;
    this.MEMBER_UPDATE_SOCKET_EVENT_NAME = `USER:PROFILE_UPDATE:MEMBER:${student._id}`
    this.MEMBER_EOS_SOCKET_EVENT_NAME = `USER:EOS_UPDATE:MEMBER:${student._id}`
    this.memberEngaged = this.student._data.memberEngagedSession || null
    this.memberEOS = this.student._data.memberShouldReceiveEOS || null
    this.memberChangesSubscription = null
    // this.updateConversations()
    this.listenForMemberChanges()
    this.listenForEOSChanges()
  }

  private listenForMemberChanges() {
    
    // If Subscribed already, no need to resubscribe
    if (!this.memberChangesSubscription) {
      this.memberChangesSubscription = this.rxsocket.on(this.MEMBER_UPDATE_SOCKET_EVENT_NAME)
        .subscribe(member => {
          // First ensure that the coming member matches the current member
          if (this.student['_id']) {
            if (this.student['_id'] === member['_id']) {
              this.student = member
              this.memberEngaged = this.student._data.memberEngagedSession || null
            }
          }
        });
    }
  }

  private listenForEOSChanges() {

    // If Subscribed already, no need to resubscribe
    // if (!this.memberEOSChangeSubscription) {
    this.memberEOSChangeSubscription = this.rxsocket.on(this.MEMBER_EOS_SOCKET_EVENT_NAME)
      .subscribe(response => {
        this.memberEOS = response.memberShouldReceiveEOS || this.memberEOS;
      });
    //}
  }

  private onMessagesLoaded = (messages = []) => {
    let shouldScrollToBottom = true;

    if (this.messages && messages && this.messages.length && messages.length) {
      const newMessagesLength = messages.length;
      const oldMessagesLength = this.messages.length;

      const isSameStudent = this.messages[0]._student === messages[0]._student;
      const isNewerMessageAvailable = this.messages[oldMessagesLength - 1].createdAt !== messages[newMessagesLength - 1].createdAt;
      const isOlderMessageAvailable = this.messages[0].createdAt !== messages[0].createdAt

      // const messagesDiff = lodash.differenceBy(this.messages, messages, 'createdAt')
      const messagesDiff = messages.filter(i => !this.messages.map(j => j.createdAt).includes(i.createdAt))

      if (isNewerMessageAvailable) {
        // this.messages = messages || [];
        this.messages = [...this.messages, ...messagesDiff]
      } else if (isOlderMessageAvailable) {
        this.messages = [...messagesDiff, ...this.messages]
      }
      shouldScrollToBottom = isSameStudent && isNewerMessageAvailable;
    }

    // If first time load of messages
    const isFreshStart = !this.messages || this.messages.length === 0;

    if (isFreshStart) {
      this.messages = messages || [];
    }

    // this.conversationService.markMessagesAsRead(this.studentId);

    // If current scroll position(index) is close to bottom
    // const isCurrentScrollAtBottom = (this.virtualScroller.viewPortInfo.endIndex > this.messages.length - 1);

    // if (shouldScrollToBottom && (isCurrentScrollAtBottom || isFreshStart)) { this.scrollToBottom(); }

    const messagingElement = document.getElementById('messages-container');

    // Check if we are at the bottom of the page, else, scroll
    const isCurrentScrollAtBottom = messagingElement.scrollHeight -
      messagingElement.scrollTop - messagingElement.clientHeight < 200


    if (isCurrentScrollAtBottom) {
      this.scrollToBottom()
    }

    if (this.appService.currentUser._role.title !== 'Coach') {
      // Check if any of the messages has potential crisis
      this.potentialCrisisTexts = _.filter(messages, item => {
        return item.isOriginalPotentialCrisis === true &&
          item.isUpdatedPotentialCrisis === true &&
          item.isCrisisUpdated === false
      })

      // Open dialog if there is potential crisis
      if (this.potentialCrisisTexts.length > 0) {
        if (!this.crisisDialogRef) {
          this.crisisDialogRef = this.crisisDetectionDialog.open(CrisisDetectionDialogComponent, {
            data: {
              messages: this.potentialCrisisTexts
            }
          });
        } else {
          if (this.crisisDialogRef.componentInstance) {
            this.crisisDialogRef.componentInstance.data.messages = this.potentialCrisisTexts
          }
        }

        this.crisisDialogRef.afterClosed().subscribe(result => {
          this.crisisDialogRef = null;
        });
      }
    }
  }

  loadMore(data?) {
    this.isLoadingMore = true;

    let params;
    let buffer = this.messages;

    if (this.isSearchMode) {
      buffer = this.searchedMessages;
    }

    if (data['type'] === 'before') {
      params = {
        before_date: buffer && buffer.length ? buffer[0].createdAt : moment().toISOString(),
        limit: 10
      };
    } else if (data['type'] === 'after') {
      params = {
        after_date: buffer && buffer.length ? buffer[buffer.length - 1].createdAt : moment().toISOString(),
        limit: 10
      };
    }


    if (this.isSearchMode) {
      return this.conversationService.getConversation(this.studentId, params)
        .subscribe((res: any) => {
          let messages = res.data;

          if (data['type'] === 'before') {
            this.searchedMessages = transformShouldCollapseName([...messages, ...this.searchedMessages]);
          } else if (data['type'] === 'after') {
            this.searchedMessages = transformShouldCollapseName([...this.searchedMessages, ...messages]);
          }

          this.isLoadingMore = false;
        });
    }

    const loadMoreFn = this.isLead ?
    () => this.conversationService.loadMoreLeadInConversation(this.leadData._id, params) :
    () => this.conversationService.loadMoreInConversation(this.studentId, params);

    loadMoreFn().subscribe(
      (res: any) => {
        this.isLoadingMore = false;
        if (res.data.length < 20) {
          this.showLoadMore = false;
        }
      }, 
      err => this.isLoadingMore = false
    );
  }

  loadSlotsForToday() {
    this.studentSlots = [];

    this.studentSlots$ =
      this.studentService.getSlotsForToday(this.studentId)
        .pipe(map((tSlots: any) => {
          const activeSlot = tSlots.find(slot => slot.active === true);

          this.isTimeSlot = Boolean(activeSlot);
          return tSlots;
        }));
  }

  handleNewLinkedRec(event) {
    this.shouldRefetchRecommendation = !this.shouldRefetchRecommendation;
  }

  copyText() {
    this.message += this.selectedCannedMessage;
  }

  sendMessage(incomingMessage = null) {
    let message = incomingMessage || this.message.trim();
    this.isMessagePosting = true;
    if (!message) {
      this.isMessagePosting = false;
      this.notificationService.toastr.warning('Can\'t send empty message');
      return;
    }

    if (this.isLead) {
      if(this.leadData._id !== this.route.snapshot.params['student-id']) {
        this.notificationService.toastr.warning('Failed to send lead message retry once again');
        return;
      }
    } else {
      if(this.student['_id'] !== this.route.snapshot.params['student-id']) {
        this.notificationService.toastr.warning('Failed to send message retry once again');
        return;
      }
    }
  
    const observable = this.isLead ? 
      this.conversationService.sendLeadText(this.leadData._id, message) :
      this.conversationService.sendText(this.student['_id'], message);

    observable.subscribe(
      text => {
        this.message = '';
        this.isMessagePosting = false;
      }, 
      err => {
        this.isMessagePosting = false;
        this.notificationService.toastr.warning('Could not send message!');
      }
    );
  }

  clearSelectAndResctrictions() {
    this.textRestrictions.isHiddenFromCoach = false;
    this.textRestrictions.isHiddenFromSupervisor = false;

    this.selectedConversationsDict = {};
    this.updateSelectedConversations();
  }

  updateSelectedConversations() {
    this.selectedConversationIds = _.transform(
      this.selectedConversationsDict,
      (result, isSelected, id) => { isSelected && result.push(id); },
      []
    );
  }

  onCheckConversation(id, ev) {
    this.selectedConversationsDict[id] = ev;
    this.updateSelectedConversations();
  }

  selectConversation(ev) {
    this.selectedConversationsDict[ev.messageId] = !this.selectedConversationsDict[ev.messageId];

    this.updateSelectedConversations();
  }

  submitConversationsToHide() {
    this.conversationService.setConversationRestrictions(
      this.selectedConversationIds,
      this.textRestrictions
    ).subscribe(() => {
      this.notificationService.toastr.info('Successfully set restrictions');
      this.clearSelectAndResctrictions();
    });
  }

  scrollToBottom() {
    // let lastIndex = this.messages ? this.messages.length : 0;
    // lastIndex = this.searchedMessages ? this.searchedMessages.length : lastIndex;

    // this.virtualScroller.scrollToIndex(lastIndex - 1);
    const elem = document.getElementById('messages-container');
    setTimeout(() => {
      elem.scrollTo({
        top: elem.scrollHeight + 1000, // 1000 for extra forceful padding
        behavior: 'smooth',
      })
    }, 300)
  }

  getStudentNotes() {
    if (this.student._data.notes) {
      return this.student._data.notes.split(/\n+/g);
    }
  }

  toggleSearchMode() {
    this.isSearchMode = !this.isSearchMode;
  }

  toggle(event){
    this.toggleCalender = event
  }

  searchService(searchQuery) {
    return this.api.get('/conversations/' + this.studentId, { 'search_query': searchQuery });
  }

  onSearchFocus() {
    this.isDropdownOpen = true;
    this.setupDropdownPositioning();
  }

  onSearchBlur() {
    setTimeout(() => {
      this.isDropdownOpen = false;
    }, 200);
  }

  private setupDropdownPositioning() {
    if (this.dropdownPositioner) {
      clearInterval(this.dropdownPositioner);
    }

    this.dropdownPositioner = setInterval(() => {
      if (!this.isDropdownOpen) {
        clearInterval(this.dropdownPositioner);
        return;
      }
      this.positionDropdown();
    }, 100);
  }

  private positionDropdown() {
    const dropdown = document.querySelector('ngb-typeahead-window') as HTMLElement;
    const input = this.searchInput?.nativeElement;
    
    if (dropdown && input) {
      const rect = input.getBoundingClientRect();
      // const parentRect = input.closest('.mat-expansion-panel').getBoundingClientRect();
      
      dropdown.style.position = 'fixed';
      dropdown.style.top = `${rect.bottom + 4}px`;
      dropdown.style.left = `${rect.left}px`;
      dropdown.style.width = `${rect.width}px`;
      dropdown.style.minWidth = '350px';
      dropdown.style.maxHeight = '300px';

      // Check if dropdown would go off screen
      const dropdownRect = dropdown.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      
      if (dropdownRect.bottom > viewportHeight) {
        dropdown.style.top = `${rect.top - dropdownRect.height - 4}px`;
      }
    }
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(term => term.length > 1),
      tap(() => {
        this.isSearching = true;
        // Position dropdown after results load
        setTimeout(() => this.positionDropdown(), 0);
      }),
      switchMap(term => 
        this.searchService(term).pipe(
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => {
        this.isSearching = false;
        // Position dropdown after results update
        setTimeout(() => this.positionDropdown(), 0);
      }),
      map((results: any) => results.length ? results : [null])
    );
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  jumpToDateInConversation(studentId: string, date: string) {
    let query = this.searchModel;

    let navigationExtras: NavigationExtras = {
      queryParams: { 'jump_to': date }
    };
    this.router.navigate(['/chat/', studentId], navigationExtras);
    setTimeout(() => this.searchModel = query);
  }

  exitSearch() {
    this.router.navigate(['/chat/', this.student._id])
      .then(() => setTimeout(() => this.scrollToBottom(), 1000));
  }

  async handleRefreshMessages() {
    try {
      this.isLoadingMore = true;
      await this.conversationService.loadConversation(this.studentId, true);
      this.scrollToBottom();
      this.conversationService.getCurrentConversation()
        .subscribe(() => {
          this.onMessagesLoaded
        });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingMore = false;
    }
  }


  onClickSetMemberEngaged(engaged) {
    this.setMemberEngagedLoading = true;
    this.studentService.setMemberEngaged(this.studentId, engaged)
      .subscribe((res: any) => {
        const data = res;
        this.notificationService.toastr
          .info(data ? data.message : 'Member status updated for this session');
        //  if(res.data) this.memberEngaged = !this.memberEngaged;
      }, err => {
        this.notificationService.toastr
          .error('Could not set session status to engaged');
      }).add(() => this.setMemberEngagedLoading = false);
  }

  onClickSetMemberEndOfShiftMessage(status) {
    this.setMemberEOSLoading = true;
    this.studentService.setEOSStatus(this.studentId, status)
      .subscribe((res: any) => {
        const data = res;
        this.memberEOS = data.memberShouldReceiveEOS;
        this.notificationService.toastr
          .info(data ? data.message : 'Member end of shift message status updated for this session');
      }, err => {
        this.notificationService.toastr
          .error('Could not change user end of shift message status');
      }).add(() => this.setMemberEOSLoading = false);
  }
}
