import {IManagerPermissionStore} from 'app/types'

export interface IAppState {
  entities?: {
    me?: {
      isAuthenticated?: boolean,
      profile?: object
    },
    users?: {
      byId?: object,
      coachesIds?: Array<string>,
      studentsIds?: Array<string>,
      assignedStudentsIds?: Array<string>
    },
    conversations?: {
      byId?: object,
      byLeadId?: object,
      allIds?: Array<string>,
      currentConversation?: string,
      assignedStudentsConversations?: Array<string>
      leadsConversations?: Array<string>
      currentLeadConversation?: string,
    },
    dashboard?: {
      byId?: object,
      studentsIds?: Array<string>,
      assignedStudentsIds?: Array<string>,
      caseloadMembersIds?: Array<string>,
      profileSelections?: object,
      managerPermissions?: IManagerPermissionStore
    },
    app?: {
      appLoading?: boolean,
      appLoadingPercentage?: number,
      appLoadingMessage?: string,
      statusOptions?: Array<object>,
    }
    moods?: any,
    feelings?: any,
    studentSlots?: any
  };
  appState?: {
    UI?: {

    }
  };
}

export const INITIAL_STATE: IAppState = {
  entities: {
    me: {
      isAuthenticated: false,
      profile: {}
    },
    users: {
      byId: {},
      coachesIds: [],
      studentsIds: []
    },
    conversations: {
      byId: {},
      byLeadId: {},
      allIds: [],
      currentConversation: null,
      assignedStudentsConversations: [],
      leadsConversations: [],
      currentLeadConversation: null
    },
    dashboard: {
      byId: {},
      studentsIds: [],
      assignedStudentsIds: []
    },
    app: {
      appLoading: false,
      appLoadingPercentage: 0,
      appLoadingMessage: '',
      statusOptions: [],
    },
    moods: [],
    feelings: [],
    studentSlots: []
  }
};
