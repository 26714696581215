import * as TYPES from 'app/redux/actions/action-types';
import { IAppState } from '../store';

const appReducer = (state = {}, action) => {
  switch (action.type) {

    case TYPES.SET_STATUS_OPTIONS:
      return { ...state, statusOptions: action.payload || [] };
    default:
      return state;
  }
};

export const SELECTORS = {
  statusOptions: (state : IAppState) => state.entities.app.statusOptions,
  genderOptions: (state: IAppState) => state.entities.dashboard.profileSelections["gender"],
  orientationOptions: (state: IAppState) => state.entities.dashboard.profileSelections["sexualOrientation"],
  raceOptions: (state: IAppState) => state.entities.dashboard.profileSelections["race"],
  coachGenderOptions: (state: IAppState) => state.entities.dashboard.profileSelections["coachGender"],
  coachOrientationOptions: (state: IAppState) => state.entities.dashboard.profileSelections["coachSexualOrientation"],
  coachRaceOptions: (state: IAppState) => state.entities.dashboard.profileSelections["coachRace"],
  coachLanguageOptions: (state: IAppState) => state.entities.dashboard.profileSelections["coachLanguages"]
};

export default appReducer;
