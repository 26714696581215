<div class="languages-wrapper">
    <div class="language-options">
        <div *ngFor="let lang of coachLanguages" class="language-option">
            <mat-checkbox [checked]="lang.selected" (change)="toggleLanguageSelection(lang.label)">
                {{lang.value}}
            </mat-checkbox>

            <mat-form-field *ngIf="lang.label === '5' && lang.selected">
                <input matInput [(ngModel)]="customLanguage" placeholder="Enter custom language">
            </mat-form-field>
        </div>
    </div>

    <div class="actions">
        <button class="save-btn" (click)="saveLanguages()">Save Languages</button>
    </div>
</div>