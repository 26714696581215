
import { of as observableOf, BehaviorSubject, of } from 'rxjs';

import { catchError, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';
import { Observable } from 'rxjs';

import { loadUsersSuccess } from 'app/redux/actions/user.actions';
import { loadAssignedStudentsSuccess, loadCaseloadMembersSuccess } from 'app/redux/actions/student.actions';
import { SELECTORS as USER_SELECTORS } from 'app/redux/reducers/user.reducer';
import { SELECTORS as DASHBOARD_SELECTORS } from 'app/redux/reducers/dashboard.reducer';
import { IManager, IMemberCoachResponse } from 'app/types/index'

import { ApiService } from './api.service';

@Injectable()
export class CoachService {

  coachesProfile = {};
  coachesArray = [];
  assignedStudents = [];
  private subject = new BehaviorSubject<boolean>(undefined);
  clickedWeek$: Observable<boolean> = this.subject.asObservable();

  constructor(
    private api: ApiService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  nextWeek(){
    return this.subject.next(true);
  }
  lastWeek(){
    return this.subject.next(false);
  }

  createCoach(coach) {
    return this.api.post('/coaches', coach);
  }

  getCoach(id: string) {
    if (this.coachesProfile[id]) {
      return observableOf(this.coachesProfile[id]);
    } else {
      return this.api.get('/coaches/' + id).pipe(
        map(res => {
          const student = res;
          this.coachesProfile[id] = student;
          return student;
        }));
    }
  }

  getCoaches() {
    return this.ngRedux.select(USER_SELECTORS.getAllCoaches).pipe(take(1));
  }

  checkPermission(permissionName: string): boolean {
    const answer = this.ngRedux.getState().entities.dashboard.managerPermissions.permissions;
    return answer.map(x => x.title).includes(permissionName);
  }

  findEngagement(pageSize, pageNumber, id): Observable<IMemberCoachResponse> {
    return this.api.getData<IMemberCoachResponse>(`/coaches/member-coaching-sessions?pageSize=${pageSize}&pageNumber=${pageNumber}&memberId=${id}`).pipe(
      map(res => res['data'])
    );
  }
  getManagerCoachPermissions(): string[] {
    const answer = this.ngRedux.getState().entities.dashboard.managerPermissions.permissions;
    let coachPermission = [];
    answer.forEach(x => {
      if (x.value && x.title.startsWith('coach-')) {
        coachPermission.push(x.value);
      }
    });
    return coachPermission;
  }

  getManagerPermission(id: string) {
    return this.api.getData<IManager>('/roles/individual-manager-permission/' + id);
  }

  getUsers() {
    return this.api.get('/accounts').pipe(map(res => res['data']));
  }

  syncPermission(): Observable<{ message: string }> {
    return this.api.getData<{ message: string }>('/coaches/permission-sync');
  }

  loadCoaches() {
    if (this.ngRedux.getState().entities.users.coachesIds.length) {
      return;
    }
    this.api.get('/coaches').subscribe((res: any) => {
      this.ngRedux.dispatch(loadUsersSuccess(res.data));
    });
  }

  async loadCoachesAsync() {
    if (this.ngRedux.getState().entities.users.coachesIds.length) {
      return;
    }
    const res: any = await this.api.get('/coaches').toPromise()
    this.ngRedux.dispatch(loadUsersSuccess(res.data));
  }

  getAllCoaches(limit = 10, page = 0) {
    return this.api.get(`/coaches?limit=${limit}&page=${page}`)
  }

  async getAllUsers(limit = 10, page = 0, searchString = '', archived = false) {
    return this.api.get(`/coaches?limit=${limit}&page=${page}&archived=${archived}&search=${searchString}`).toPromise()
  }

  async getUserDetails(userId) {
    return this.api.get(`/coaches/${userId}`).toPromise()
  }

  updateCoachLanguageSelections(coachId, coachLanguages) {
    return this.api.patch(`/coaches/${coachId}/set-coach-languages`, {
      coachLanguages: coachLanguages
    }).pipe(
      catchError((err) => {
        console.error("Error updating languages:", err);
        return of({
          error: true,
          message: err.error?.message || "Failed to update languages",
          data: null
        });
      })
    );
  }

  getAssignedStudents() {
    return this.ngRedux.select(DASHBOARD_SELECTORS.getAllAssignedStudents);
  }

  getAssignedStudentsDashboard() {
    return this.ngRedux.select(DASHBOARD_SELECTORS.getAllAssignedStudentsDashboard);
  }

  getCaseloadMembersDashboard() {
    return this.ngRedux.select(DASHBOARD_SELECTORS.getAllCaseloadMembersDashboard);
  }

  async loadAssignedStudents(force?, limit = null, page = 0, options = {}) { // TODO: USE BETTER PAGINATION
    // if not forced, do not load, just use from redux as cache
    const filters = JSON.stringify(options)
    if (!force && this.ngRedux.getState().entities.dashboard.assignedStudentsIds.length) {
      return;
    }
    const response: any = await this.api.get(`/coaches/me/students?limit=${limit}&page=${page}&filters=${filters}`)
      .toPromise()
    this.ngRedux.dispatch(loadAssignedStudentsSuccess(response.data.items));
  }

  async loadAssignedMemberDetails(force?, limit = null, page = 0, options = {}) {
    // if not forced, do not load, just use from redux as cache
    const filters = JSON.stringify(options)
    if (!force && this.ngRedux.getState().entities.dashboard.assignedStudentsIds.length) {
      return;
    }
    const response: any = await this.api.get(`/coaches/me/students?limit=${limit}&page=${page}&filters=${filters}`)
      .toPromise()
    return response.data
  }

  async loadAssignedMemberFilters() {
    const response: any = await this.api.get(`/coaches/me/students/filters`)
      .toPromise()
    return response.data
  }

  loadCaseloadMembers(force?) {
    // if not forced, do not load, just use from redux as cache
    if (!force && this.ngRedux.getState().entities.dashboard.caseloadMembersIds.length) {
      return;
    }
    this.api.get('/coaches/me/caseload')
      .subscribe((res: any) => {
        this.ngRedux.dispatch(loadCaseloadMembersSuccess(res));
      });
  }

  updateCoach(id: string, data) {
    return this.api.patch('/coaches/' + id, data).pipe(
      map((res: any) => {
        let coach = res;
        delete coach.password;
        return coach;
      }));
  }

  deleteCoach(id: string) {
    return this.api.delete('/coaches/' + id);
  }

  runMemberAssignments() {
    return this.api.post('/coaches/run-member-assignments');
  }


  runCaseloadCheckIn() {
    return this.api.post('/coaches/run-caseload-check-in');
  }

  clearMemberAssignments() {
    return this.api.post('/coaches/clear-member-assignments');
  }

  runNPSSurveyAdministration() {
    return this.api.post('/coaches/run-nps-survey');
  }

  runSWEMWBSSurveyAdministration() {
    return this.api.post('/coaches/run-swemwbs-survey');
  }

  getAllStatusTransitions(coachId: string, pageSize = 0, pageNumber = 0) {
    return this.api.getData<any>(`/coaches/all-status-transitions?coachId=${coachId}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
  }

  transitionCoachStatus(coachId: String, status: String) {
    return this.api.put('/coaches/transition-coach-status', {
      coachId,
      status,
    });
  }

  getMemberCoachingSessions(memberId, pageSize = 5, pageNumber = 0) {
    return this.api.get(`/coaches/member-coaching-sessions?memberId=${memberId}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
  }

  getPotentialCrisisTexts() {
    return this.api.get('/coaches/potential-crisis-conversations');
  }

  getCoachShiftSubstitutionHistory(coachId, pageNumber = 0, pageSize = 0) {
    return this.api.getData<any>(`/coaches/coach-substitution-history?coachId=${coachId}&pageSize=${pageSize}&pageNumber=${pageNumber}`)
  }

  coachRequestShiftSubstitution(shiftTimes, shiftDay, notes) {
    return this.api.put('/coaches/request-shift-substitution', {
      shiftTimes,
      shiftDay,
      notes,
    });
  }

  updateUserRole(data): Observable<{ message: string }> {
    return this.api.patchData<{ message: string }>('/accounts/role', data);
  }

  adminSubstituteCoach(refId, coachIdToSubstitute, notes) {
    return this.api.post('/coaches/substitute-coach', {
      refId,
      coachIdToSubstitute,
      notes,
    });
  }

  runCoachStatusTransitions() {
    return this.api.post('/coaches/run-coach-status-transition');
  }

  runWellnessDataUpload() {
    return this.api.post('/coaches/process-wellness-upload');
  }

  runWellnessDataFollowUps() {
    return this.api.post('/coaches/process-wellness-follow-ups');
  }

  getStudentSwembsScoreChange(memberId) {
    return this.api.get(`/coaches/${memberId}/swembschange`)
  }
  
  getSchedule(lssId) {
    return this.api.get(`/lsses/schedule?userId=${lssId}` )
  }

  setSchedule(userId, lssSchedule) {
    return this.api.post('/lsses/schedule', {
       userId, lssSchedule
    })
  }
  getCoachesAssignment(date: string) {
    return this.api.getData<any>(`/coaches/assignment-schedule?date=${date}`)
  }

  getLssSchedule(startTime , endTime, shiftTime, oldRecord = false) {
    return this.api.post('/lsses/all-schedules', {
      startTime, endTime, shiftTime, oldRecord
    });
  }

  restoreCoach(id: string): Observable<Object> {
    return this.api.patch(`/coaches/${id}/unarchive`);
  }

  adminRequestShiftSubstitution(substitutionData) {
    return this.api.post('/coaches/admin-request-shift-substitution', substitutionData);
  }

  deleteShiftSubstitution(substitutionId: string) {
    return this.api.delete(`/coaches/shift-substitution/${substitutionId}`);
  }

  getWellnessRecordsByFilter(memberIds: string[], startDate = '', endDate = '', limit = 0) {
    return this.api.post('/coaches/get-wellness-data-by-filter', {
      startDate, endDate, members: memberIds, limit
    });
  }

  getPotentialCrisisTextsByFilter(memberIds: string[]) {
    return this.api.post('/coaches/potential-crisis-conversations-by-filter', { members: memberIds })
      .pipe(map(res => res['data']));
  }

  getActiveCoaches() {
    return this.api.getData('/coaches/active/count');
  }

  editCoachSchedule(id: string, data) {
    return this.api.patch(`/coaches/${id}/schedule`, data).pipe(map(res => res['data']));
  }

  getMembersAssignedToday(coachId: string) {
    return this.api.getData(`/coaches/${coachId}/members-today`).pipe(map(res => res['data']));
  }
}
