import { combineReducers } from 'redux';
import { getSlotHumanReadableFormat, calculateAgeFromDate } from 'app/helpers/date';

import * as TYPES from 'app/redux/actions/action-types';
import { normal } from './schema';

import * as _ from 'lodash';

function setAllStudentsIsSelected(studentsById, isSelected) {
  let dashStudents = { ...studentsById };
  Object.keys(dashStudents).forEach(key => {
    dashStudents[key] = { ...dashStudents[key], isSelected };
  });
  return dashStudents;
}

const filters = {
  'First Name': (student, firstName) => {
    return firstName === 'First Name' ? student.firstName : !student.firstName;
  },
  'City': (student, city) => student.city === city,
  'Partner': (student, partner) => {
    if (student._data.primaryPartnerName) {
      return student._data.primaryPartnerName === partner;
    }
    return false;
  },
  'Gender': (student, gender) => student.gender === gender,
  'Age': (student, age) => calculateAgeFromDate(student.dob) === parseInt(age, 10),
  'Race': (student, race) => student._data.races.includes(String(race)),
  'Orientation': (student, orientation) => student._data.sexualOrientation === orientation,
  'Timeslot': (student, timeSlot) => {
    let index = student.timeSlotsToday.findIndex(slot => {
      return timeSlot === getSlotHumanReadableFormat(slot);
    });

    if (index > -1) {
      return true;
    }

    return false;
  },
  'Current Status': (student, currentStatus) => {
    if (currentStatus === 'EXITED') {
      return student._data.currentStatus.includes('EXITED');
    } else {
      return student._data.currentStatus === currentStatus;
    }
  }
};

const memberFilters = {
  status: (student, currentStatus) => {
    if ( currentStatus === '--NONE--' ) {
      return !student._data.currentStatus;
    } else if (currentStatus === 'EXITED') {
      return student._data.currentStatus.includes('EXITED');
    } else {
      return student._data.currentStatus === currentStatus;
    }
  },
  partner: (student, partner) => {
    if ( partner === '--NONE--' ) {
      return student._data._partners.length === 0;
    } else if (student._data._partners) {
      return student._data._partners.filter(item => item._id === partner).length > 0;
    }
    return false;
  },
  coachTeam: (student, team) => {
    if ( team === '--NONE--' ) {
      if (Array.isArray(student._data.coachTeam)) {
        return student._data.coachTeam.length === 0;
      }
      return !student._data.coachTeam;
    } else if (team.length === 0) {
      if (Array.isArray(student._data.coachTeam)) {
        return student._data.coachTeam.length === 0;
      }
      return student._data.coachTeam === '' || student._data.coachTeam === 'undefined' ||
        student._data.coachTeam === undefined;
    }
    if (student._data.coachTeam) {
      return team.filter(id => student._data.coachTeam.includes(id)).length > 0;
    }
    return false;
  },
  shiftTime: (student, time) => {
    if ( time === '--NONE--' ) {
      return !student._data.shiftTime;
    } else if (student._data.shiftTime) {
      return student._data.shiftTime.includes(time);
    }
    return false;
  },
  shiftDay: (student, day) => {
    if ( day === '--NONE--' ) {
      if (Array.isArray(student._data.shiftDays)) {
        return student._data.shiftDays.length === 0;
      }
      return !student._data.shiftDays;
    } else if (student._data.shiftDays) {
      return day === '8' || student._data.shiftDays.includes(day);
    }
    return false;
  },
  wellnessSurveyGroup: (member, wellnessSurvey) => {
    if ( wellnessSurvey === '--NONE--' ) {
      return !member._data.wellnessSurveyGroup;
    } else if (member._data.wellnessSurveyGroup) {
      return member._data.wellnessSurveyGroup === wellnessSurvey;
    }
    return false;
  },
  raceAndEthnicity: (student, race) => {
    if ( race === '--NONE--' ) {
      if ( Array.isArray(student._data.races) ) {
        return student._data.races.length === 0;
      }
      return !student._data.races;
    }
    return student._data.races.includes(String(race));
  },
  gender: (student, gender) => {
    if ( gender === '--NONE--' ) {
      return !student._data.gender;
    }
    return student.gender === gender;
  },
  orientation: (student, orientation) => {
    if ( orientation === '--NONE--' ) {
      return !student._data.sexualOrientation;
    }
    return student._data.sexualOrientation === orientation;
  },
  city: (student, city) => {
    if ( city === '--NONE--' ) {
      return !student._data.city;
    }
    return student.city === city;
  },
};

const isActiveReducer = (state = null, action) => {
  switch (action.type) {
    case TYPES.DASHBOARD_SET_IS_ACTIVE_FILTER:
      return action.isActiveFilter;
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.LOAD_ASSIGNED_STUDENTS_SUCCESS:
      let data = {
        users: action.students
      };
      const normalizedUsers = normal(data).entities.users;

      Object.keys(state).forEach(key => {
        if (normalizedUsers && normalizedUsers[key]) {
          normalizedUsers[key]['isSelected'] = state[key]['isSelected'];
          normalizedUsers[key]['messageInput'] = state[key]['messageInput'];
        }
      });
      return { ...state, ...normalizedUsers };
    case TYPES.LOAD_CASELOAD_MEMBERS_SUCCESS:
      let memberData = {
        users: action.students
      };
      const normalizedMembers = normal(memberData).entities.users;

      Object.keys(state).forEach(key => {
        if (normalizedMembers && normalizedMembers[key]) {
          normalizedMembers[key]['isSelected'] = state[key]['isSelected'];
          normalizedMembers[key]['messageInput'] = state[key]['messageInput'];
        }
      });
      return { ...state, ...normalizedMembers };
    case TYPES.UPDATE_STUDENT_SUCCESS:
      let studentId = action.student._id;
      return { ...state, [studentId]: action.student };
    case TYPES.UPDATE_STUDENT_AWAY_MESSAGE_SUCCESS:
      let student = { ...state[action.studentId] };
      if (student._data) {
        student._data.isAwayMessageOn = action.isAwayMessageOn;
        return { ...state, [action.studentId]: student };
      }
      return state;
    case TYPES.ON_NEW_STUDENT:
      return { ...state, [action.student._id]: action.student };
    case TYPES.DASHBOARD_TOGGLE_STUDENT_SELECT:
      let dashStudent = state[action.studentId];
      return { ...state, [action.studentId]: { ...dashStudent, isSelected: dashStudent.isSelected } };
    case TYPES.DASHBOARD_SELECT_ALL_STUDENTS:
      return setAllStudentsIsSelected(state, true);
    case TYPES.DASHBOARD_DESELECT_ALL_STUDENTS:
      return setAllStudentsIsSelected(state, false);
    case TYPES.DASHBOARD_PASTE_TO_SELECTED_STUDENTS:
      let dashStudents = { ...state };
      Object.keys(dashStudents).forEach(key => {
        if (dashStudents[key].isSelected) {
          dashStudents[key] = { ...dashStudents[key], messageInput: action.message };
        }
      });
      return dashStudents;
    case TYPES.DASHBOARD_CLEAR_ALL_INPUT_BOXES:
      let allDashStudents = { ...state };
      Object.keys(allDashStudents).forEach(key => {
        allDashStudents[key] = { ...allDashStudents[key], messageInput: '' };
      });
      return allDashStudents;
    case TYPES.DASHBOARD_FILTER_STUDENTS:
      let students = { ...state };

      _.values(students).map((data: any) => {
        let isFiltered;

        for (let item of Object.keys(action.filters)) {
          if (isFiltered) {
            break;
          }
          const filterKey = item;
          const filterValue = action.filters[item];

          if (filterValue === '') {
            isFiltered = false;
            continue;
          }
          if (memberFilters[filterKey](data, filterValue)) {
            isFiltered = false;
          } else {
            isFiltered = true;
          }
        }

        students[data._id] = { ...students[data._id], isFiltered };
      });
      // Object.keys(students).forEach(key => {
      //   let isFiltered;
      //   if (action.filterValue === 'CLEAR' || action.filterValue === '' ||
      //     action.filterValue === []) {
      //     // Exclude filters
      //     return false;
      //   } else if (action.filterValue === 'undefined') {
      //     action.filterValue = undefined;
      //   }
      //   if (memberFilters[action.baseFilter](students[key], action.filterValue)) {
      //     isFiltered = false;
      //   } else {
      //     isFiltered = true;
      //   }

      //   students[key] = { ...students[key], isFiltered };
      // });
      return students;
    case TYPES.DASHBOARD_CLEAR_FILTER_STUDENTS:
      let studentsToClear = { ...state };
      Object.keys(studentsToClear).forEach(key => {
        studentsToClear[key] = { ...studentsToClear[key], isFiltered: false };
      });
      return studentsToClear;
    default:
      return state;
  }
};

const studentsIdsReducer = (state = [], action) => {
  switch (action.type) {
    case TYPES.ADD_STUDENT_SUCCESS:
      return [...state, action.student._id];
    case TYPES.LOAD_ASSIGNED_STUDENTS_SUCCESS:
      let data = {
        users: action.students
      };
      return normal(data).result.users;
    case TYPES.LOAD_CASELOAD_MEMBERS_SUCCESS:
      let memberData = {
        users: action.students
      };
      return normal(memberData).result.users;
    case TYPES.ON_NEW_STUDENT:
      return [action.student._id, ...state];
    default:
      return state;
  }
};

interface Ipermission {
  manager: string [],
  title: string,
  _id: string,
  name: string,
  description: string
}

function managerReducer (state = { permissions:[] }, action) {
  const {permissions} = action;
  switch (action.type) {
    case TYPES.LOAD_MANAGERS_PERMISSION_SUCCESS:
      return {...state, permissions};
    default:
      return state;
  }
}

function assignedStudentsIdsReducer(state = [], action) {
  switch (action.type) {
    case TYPES.LOAD_ASSIGNED_STUDENTS_SUCCESS:
      let data = {
        users: action.students
      };
      return normal(data).result.users;
    case TYPES.ON_NEW_STUDENT:
      return [action.student._id, ...state];
    default:
      return state;
  }
}

function caseloadMembersIdsReducer(state = [], action) {
  switch (action.type) {
    case TYPES.LOAD_CASELOAD_MEMBERS_SUCCESS:
      let data = {
        users: action.students
      };
      return normal(data).result.users;
    case TYPES.ON_NEW_STUDENT:
      return [action.student._id, ...state];
    default:
      return state;
  }
}

const profileSelectionsReducer = (
  state = {
    gender: {},
    sexualOrientation: {},
    pronouns: {},
    race: {},
    partners: [],
    coachGender: {},
    coachSexualOrientation: {},
    coachRace: {},
    coachLanguages: {}
  },
  action
) => {
  switch (action.type) {
    case TYPES.LOAD_GENDER_SELECTIONS_SUCCESS:
      return Object.assign(state, { gender: action.genderSelections });
    case TYPES.LOAD_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS:
      return Object.assign(state, { sexualOrientation: action.orientationSelections });
    case TYPES.LOAD_RACE_SELECTIONS_SUCCESS:
      return Object.assign(state, { race: action.raceSelections });
    case TYPES.LOAD_PRONOUNS_SELECTIONS_SUCCESS:
      return Object.assign(state, { pronouns: action.pronounSelections });
    case TYPES.LOAD_PARTNERS_SELECTIONS_SUCCESS:
      return Object.assign(state, { partners: action.partnerSelection });
    case TYPES.LOAD_COACH_GENDER_SELECTIONS_SUCCESS:
      return Object.assign(state, { coachGender: action.coachGenderSelections });
    case TYPES.LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS:
      return Object.assign(state, { coachSexualOrientation: action.coachSexualOrientationSelections });
    case TYPES.LOAD_COACH_RACE_SELECTIONS_SUCCESS:
      return Object.assign(state, { coachRace: action.coachRaceSelections });
    case TYPES.LOAD_COACH_LANGUAGES_SELECTIONS_SUCCESS:
      return Object.assign(state, { coachLanguages: action.coachLanguages });
    default:
      return state;
  }
};

export const SELECTORS = {
  getAllStudents: (state) => state.entities.users
    .allIds.map(id => state.entities.users.byId[id]),

  getAllAssignedStudents: (state) => state.entities.dashboard
    .assignedStudentsIds.map(id => state.entities.dashboard.byId[id]),

  getAllAssignedStudentsDashboard: (state) => state.entities.dashboard
    .assignedStudentsIds.map(id => state.entities.dashboard.byId[id])
    .filter(student => !student.isFiltered)
    // .filter(student => !student._data.isExited)
    .filter(student => {
      const currentFilterState = state.entities.dashboard.isActiveFilter;
      if (state.entities.dashboard.isActiveFilter === null) {
        return true;
      }
      const isActive = student._data.isActive;
      const isStudentActive = isActive || typeof isActive === 'undefined';

      return currentFilterState === isStudentActive;
    }),

  getAllCaseloadMembersDashboard: (state) => state.entities.dashboard
    .caseloadMembersIds.map(id => state.entities.dashboard.byId[id])
    .filter(student => !!student)
    .filter(student => !student.isFiltered)
    // .filter(student => !student._data.isExited)
    .filter(student => {
      const currentFilterState = state.entities.dashboard.isActiveFilter;
      if (state.entities.dashboard.isActiveFilter === null) {
        return true;
      }
      const isActive = student._data.isActive;
      const isStudentActive = isActive || typeof isActive === 'undefined';

      return currentFilterState === isStudentActive;
    }),

  getGenderProfileSelections: (state) => state.entities.dashboard.profileSelections['gender'],
  getSexualOrientationProfileSelections: (state) => state.entities.dashboard.profileSelections['sexualOrientation'],
  getPronounsSelections: (state) => state.entities.dashboard.profileSelections['pronouns'],
  getRaceProfileSelections: (state) => state.entities.dashboard.profileSelections['race'],
  getCoachLanguageSelections: (state) => state.entities.dashboard.profileSelections['coachLanguages']
};


export default combineReducers({
  isActiveFilter: isActiveReducer,
  byId: byIdReducer,
  studentsIds: studentsIdsReducer,
  assignedStudentsIds: assignedStudentsIdsReducer,
  caseloadMembersIds: caseloadMembersIdsReducer,
  profileSelections: profileSelectionsReducer,
  managerPermissions: managerReducer
});
